import Papa from 'papaparse';

const textarea = document.createElement("textarea");

function parseFolder(item, id) {
    return {
        id: id,
        type: "folder",
        name: item[0] || item[1],
        image: item[3],
        items: []
    };
}

function parseGame(item, id) {
    textarea.innerHTML = decodeURI(item[4]);

    return {
        id: id,
        type: "game",
        name: item[2],
        image: item[3],
        url: textarea.value,
    };
}

export default {
    actions: {
        fetchMiniGames(ctx) {
            fetch('https://docs.google.com/spreadsheets/d/1iM8mF-tyPnmTDJfg83f0e4jh6gsKJOZVMJxFsKXYVuE/gviz/tq?tqx=out:csv')
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    return response.text();
                })
                .then(text => {
                    const parsedData = Papa.parse(text, { }).data;
                    parsedData.shift();

                    const miniGamesMap = {};

                    let typeFolder = null;
                    let difficultyFolder = null;
                    let id = 0;

                    for (const item of parsedData) {
                        if (item[0] !== "") {
                            typeFolder = parseFolder(item, id++);
                            miniGamesMap[typeFolder.id] = typeFolder;
                        } else if (item[1] !== "") {
                            difficultyFolder = parseFolder(item, id++);
                            miniGamesMap[typeFolder.id].items[difficultyFolder.id] = difficultyFolder;
                        } else if (item[2] !== "") {
                            const game = parseGame(item, id++);
                            miniGamesMap[typeFolder.id].items[difficultyFolder.id].items[game.id] = game;
                        }
                    }

                    console.log(miniGamesMap);

                    ctx.commit('setMiniGames', miniGamesMap);
                });
        },
    },

    mutations: {
        setMiniGames(state, miniGames) {
            state.miniGames.items = miniGames;
        },
    },

    state: {
        miniGames: {
            "items": []
        }
    },

    getters: {
        getMiniGames(state) {
            return state.miniGames;
        },
    }
}