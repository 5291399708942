import { createStore } from 'vuex';
import { externalSiteUrl } from '@/assets/library/config';

import WheelPageStore from "@/store/modules/pages/WheelPageStore";
import LibraryStore from "@/store/modules/assets/LibraryStore";
import ProfileStore from "@/store/modules/global/ProfileStore";
import AvatarPage from "@/store/modules/pages/AvatarPage";
import ApiStore from "@/store/common/api/ApiStore";
import GalleryStore from "@/store/modules/pages/GalleryStore";
import MiniGames from "@/store/modules/pages/MiniGames";

export default createStore({
  // strict: true,
  modules: {
    WheelPageStore,
    LibraryStore,
    ProfileStore,
    GalleryStore,
    AvatarPage,
    MiniGames,
    ApiStore,
  },
})
