
export default {
    actions: {
        fetchProfileData( ctx ) {
            ctx.dispatch('apiProfileInfo', {
                success: (data) => {
                    ctx.commit('updateProfileData', data);
                }
            });
        },
    },

    mutations: {
        updateProfileData( state, data ) {
            state.profileData = data;
        },

        setProfileStatus( state, data ) {
            state.profileData.status = data;
        }
    },

    state: {
        profileData: {},
    },

    getters: {
        getProfileData( state ) {
            return state.profileData;
        },

        getAvatarUrl( state ) {
            if (state.profileData.id !== undefined) {
                return process.env.VUE_APP_API_BASE_URL + 'static/player/avatar/' +
                    state.profileData.id + '.png?t=' + Date.now();
            }

            return null;
        }
    }
}