<template>
  <div class="map page__wrap__container__view">
    <router-link class="map__link" to="/avatar">
      <AvatarPreview :update="false" class="map-avatar"/>
    </router-link>
    <MapLink :external="true" name="homework"></MapLink>
    <MapLink :external="true" image="battle-ring" name="battle"></MapLink>
    <MapLink :external="true" name="tower"></MapLink>
    <MapLink :external="true" name="calc"></MapLink>
    <MapLink :external="true" name="soroban"></MapLink>
    <MapLink :external="true" name="olympic"></MapLink>

    <MapLink :external="false" name="gallery"></MapLink>

    <MapLink :external="false" name="wheel" :active="false"></MapLink>
    <MapLink :external="false" name="movies" :active="false"></MapLink>
    <MapLink :external="false" name="house-1" :active="false"></MapLink>
    <MapLink :external="false" name="minigames" :active="true"></MapLink>
    <MapLink :external="false" name="house-3" :active="false"></MapLink>
    <MapLink :external="false" name="reactor" :clickable="false"></MapLink>
    <MapLink :external="false" name="store" :active="false"></MapLink>
    <MapLink :external="false" name="skyscrapper" :active="false"></MapLink>
    <MapLink :external="false" name="home" :active="false"></MapLink>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BaseExternalLink from "@/components/base/BaseExternalLink";
import MapImage from "@/modules/map/MapImage";
import BaseImage from "@/components/base/BaseImage";
import AvatarPreview from "@/modules/avatar/AvatarPreview";
import MapLink from "@/modules/map/MapLink";

export default {
  name: 'MapPage',
  components: {MapLink, BaseExternalLink, MapImage, BaseImage, AvatarPreview},
  methods: {
    ...mapMutations(['decrementPreloaderState', 'incrementPreloaderState']),
  },

  computed: {
    ...mapGetters(['getProfileData', 'getAvatarUrl']),
  },

  mounted() {
  }


}

</script>