<template>
  <div class="map__link" @click="onClick">
    <v-img :src="src" :eager="true" :class="classObject"/>
  </div>
</template>

<script>
import MapImage from "@/modules/map/MapImage";
export default {
  name: "MapLink",
  components: {MapImage},
  props: {
    name: {type: String, required: true},
    clickable: {type: Boolean, default: true},
    external: {type: Boolean, required: true},
    active: {type: Boolean, default: true},
    image: {type: String, default: null},
  },
  data() {
    return {
      classObject: [
        this.name,
        {
          'active': this.active
        },
      ],
      src: '/static/img/map/' + (this.image === null ? this.name : this.image) + '.png',
    }
  },
  methods: {
    onClick() {
      if (this.active === false || this.clickable === false) {
        return false;
      }

      if (this.external) {
        const url = process.env.VUE_APP_PLATFORM_BASE_URL + process.env.VUE_APP_GAME_PATH + "#" + this.name;
        window.open(url, '_blank').focus();
      } else {
        this.$router.push('/' + this.name);
      }
    }
  }
}
</script>